import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import AdminEventsNavbar from '../AdminEventsNavbar';
import Switch from "react-switch";
import Select from 'react-select'
import { MdAdd, MdCancel, MdDelete } from 'react-icons/md';
import ShortTextComponent from './form/ShortTextComponent';
import LongTextComponent from './form/LongTextComponent';
import ListComponent from './form/ListComponent';
import SingleSelectionComponent from './form/SingleSelectionComponent';
import CheckboxComponent from './form/CheckboxComponent';

export default function EventForms() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  let event_id = new URLSearchParams(window.location.search).get("event_id")
  let selectedItem = new URLSearchParams(window.location.search).get("state") ? new URLSearchParams(window.location.search).get("state") : "general"

  const [eventData, setEventData] = useState({
    name: "",
    description: "",
    event_start_datetime: "",
    event_end_datetime: "",
    venue: "",
    price: "0",
    is_unlimited_capacity: "0",
    capacity: "100",
    has_accommodation: "0",
    has_transportation: "0",
    accommodation_id: "",
    transportation_id: "",
    registration_start_datetime: "",
    registration_end_datetime: "",
  })

  const [accommodations, setAccommodations] = useState([]) //
  const [selectedAccommodation, setSelectedAccommodation] = useState(null)
  const [formQuestions, setFormQuestions] = useState([])
  const [mandartoryFields, setMandartoryFields] = useState([])

  const SMART_TAG = [
    {
      label: "None",
      value: "none",
      field_type: "text",
      type: "text"
    },
    {
      label: "Full Name",
      value: "fullname",
      field_type: "text",
      type: "text"
    },
    {
      label: "Email Address",
      value: "email",
      field_type: "text",
      type: "text"
    },
    {
      label: "Contact Number",
      value: "contact_number",
      field_type: "text",
      type: "text"
    },
    {
      label: "Gender",
      value: "gender",
      field_type: "radio",
      type: "text",
    },
    {
      label: "Passport Number",
      value: "passport_number",
      field_type: "text",
      type: "text"
    },
    {
      label: "Passport Expiry Date",
      value: "passport_expiry_date",
      field_type: "text",
      type: "date"
    },
    {
      label: "Date of Birth",
      value: "date_of_birth",
      field_type: "text",
      type: "date"
    },
    {
      label: "Remarks",
      value: "remarks",
      field_type: "text",
      type: "text"
    },
    {
      label: "Transport",
      value: "transport",
      field_type: "text",
      type: "text"
    },
  ]

  const MANDATORY_FIELDS = {
    general: [
      {
        name: "Full Name",
        smart_tag: "fullname",
        field_type: "text",
      },
      {
        name: "Email Address",
        smart_tag: "email",
        field_type: "text",
        type: "email"

      },
      {
        name: "Contact Number",
        smart_tag: "contact_number",
        field_type: "text",
        type: "tel"
      },
      {
        name: "Gender",
        smart_tag: "gender",
        field_type: "radio",
        type: "text",
        options: ["Male", "Female"]

      },
    ],
    transportation:[
      {
        name: "Transport",
        smart_tag: "transport",
        field_type: "radio",
        type: "text",
        options: ["Yes", "No"]

      },
    ],
    accommodation: [
      {
        name: "Passport Number",
        smart_tag: "passport_number",
        field_type: "text",
        type: "text"
      },
      {
        name: "Passport Expiry Date",
        smart_tag: "passport_expiry_date",
        field_type: "text",
        type: "date"
      },
      {
        name: "Date of Birth",
        smart_tag: "date_of_birth",
        field_type: "text",
        type: "date"
      },
    ],
  }

  const FIELD_TYPE = [
    { value: "text", label: "Short Text" },
    { value: "textarea", label: "Long Text" },
    // { value: "select", label: "List" },
    { value: "radio", label: "Selection" },
    { value: "checkbox", label: "Checkbox" },
  ]


  const fetchAccommodations = async () => {
    if (!event_id) return
    let endpoint = `${config.endpoint}/events/accommodations/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setAccommodations(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }




  const fetchEventById = async () => {
    if (!event_id || event_id === "new") return
    let endpoint = `${config.endpoint}/events/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setEventData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  useEffect(() => {
    fetchEventById()
    fetchAccommodations()
  }, [])


  useEffect(() => {
    generate_mandatory_fields()
  }, [accommodations])

  useEffect(() => {
    checkMandatoryFields()
  }, [formQuestions])


  const generate_mandatory_fields = () => {
    let fields = [...MANDATORY_FIELDS.general]
    if (eventData?.has_accommodation == "1") {
      fields.push(...MANDATORY_FIELDS.accommodation)
    }
    if (eventData?.has_transportation == "1") {
      fields.push(...MANDATORY_FIELDS.transportation)
    }
    setMandartoryFields(fields)
  }


  const checkMandatoryFields = () => {
    let status = true;
    let fields = [...MANDATORY_FIELDS.general]
    if (eventData?.has_accommodation == "1") {
      fields.push(...MANDATORY_FIELDS.accommodation)
    }

    if (eventData?.has_transportation == "1") {
      fields.push(...MANDATORY_FIELDS.transportation)
    }

    for (let index = 0; index < fields.length; index++) {
      let field = fields[index];
      let found = formQuestions.find(question => question.smart_tag === field.smart_tag)
      if (!found) {
        status = false;
        break
      }
    }
    return status;
  }


  const questionModel = {
    name: "",
    field_type: "text",
    field_options: [],
    is_required: "1",
    smart_tag: "none",
    type: "",
    remarks: "",
  }

  const updateQuestions = async () => {
    let endpoint = `${config.endpoint}/events/forms/`
    let data = {
      event_id: event_id,
      questions: formQuestions
    }
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data)
    })
    let res = await response.json()
    if (res.status === 200) {
      notify({ type: "success", message: res.message })
    } else {
      notify({ type: "error", message: res.message })
    }
  }

  const loadEventRegistrationForm = async () => {
    let endpoint = `${config.endpoint}/events/forms/id/${event_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setFormQuestions(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  useEffect(() => {
    loadEventRegistrationForm()
  }, [event_id])



  return (
    <div>
      {/* Floating Save */}
      <div className="floating"
        style={{ position: "fixed", bottom: 20, right: 0, width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "0 20px", zIndex:500 }}
      >
        <div style={{ backgroundColor: "white", width: 500, padding: "10px 20px", boxShadow: "0 0 10px rgba(0,0,0,0.1)", borderRadius:10}} className='row justify-between items-center'>
          <p>Save form</p>
          <button className="button" style={{ fontSize: 12, padding: "5px 10px" }}
            onClick={() => {
              updateQuestions()
            }}
          >Save Now</button>
        </div>
      </div>

      <div className="flex-row flex gap-2 alignStart">
        <div className="w-100">
          <div className="card">
            <div className="flex-row flex gap-2 items-center justify-between">
              <p className="card-title">Form Builder</p>
              <button className="backgroundLess gap-2 flex-row items-center flex" style={{ fontSize: 12 }}
                onClick={() => {
                  let newQuestion = { ...questionModel }
                  setFormQuestions([...formQuestions, newQuestion])
                }}
              >
                <MdAdd />
                Question
              </button>
            </div>

            {!checkMandatoryFields() && <div className='mt-10' style={{ border: "1px dotted gray", padding: "10px 20px" }}>
              <p className="small mb-4">Madatory Fields</p>
              {mandartoryFields.map((field, index) => {
                if (formQuestions.filter(data => data.smart_tag === field.smart_tag).length > 0) {
                  return console.log(`Field ${field.name} already exists`)
                }
                return (
                  <div className="flex-row flex gap-2 items-center cursor-pointer" style={{ padding: "10px 20px", border: "1px dotted green", marginBottom: 10 }}
                    onClick={() => {
                      let newQuestion = { ...questionModel }
                      newQuestion.name = field.name
                      newQuestion.smart_tag = field.smart_tag
                      newQuestion.field_type = field.field_type
                      newQuestion.type = field.type
                      if (field.options) {
                        newQuestion.field_options = field.options
                      }
                      setFormQuestions([...formQuestions, newQuestion])
                    }}
                  >
                    <p className="small">{field.name}</p>
                  </div>
                )
              })}
            </div>}

            {formQuestions.length > 0 ? formQuestions.map((question, index) => {
              return (
                <div className='mt-4 pb-2' style={{ borderBottom: index + 1 !== formQuestions?.length ? "2px solid gray" : "" }}>
                  <div className="flex-row flex justify-between items-center">
                    <p className="b medium" style={{ fontSize: 14 }}>Question {index + 1}</p>
                    <div
                      className="flex-row flex gap-2 items-center"
                    >
                      <button className="backgroundLess"
                        onClick={() => {
                          let newQuestions = [...formQuestions]
                          newQuestions.splice(index, 1)
                          setFormQuestions(newQuestions)
                        }}
                      >
                        <MdDelete />
                      </button>
                      <button className="backgroundLess"
                        onClick={() => {
                          let newQuestion = { ...questionModel }
                          // setFormQuestions([...formQuestions, newQuestion])
                          let newQuestions = [...formQuestions].slice(0, index + 1)
                          newQuestions.push(newQuestion)
                          newQuestions = newQuestions.concat([...formQuestions].slice(index + 1))
                          setFormQuestions(newQuestions)
                        }}
                      >
                        <MdAdd />
                      </button>
                    </div>
                  </div>

                  <div className="gap-2 items-center">
                    <div className="flex-row flex gap-2 items-center">
                      <div className="input-container">
                        <label htmlFor="" className="input-label">Question</label>
                        <input type="text" className="text-input" value={question.name} onChange={(e) => {
                          let newQuestions = [...formQuestions]
                          newQuestions[index].name = e.target.value
                          setFormQuestions(newQuestions)
                        }} />
                      </div>
                      <div className="flex-row flex gap-2 items-center">
                        <label htmlFor="" className="input-label">Required</label>
                        <Switch
                          onChange={(e) => {
                            let newQuestions = [...formQuestions]
                            newQuestions[index].is_required = e ? "1" : "0"
                            setFormQuestions(newQuestions)
                          }}
                          checked={question.is_required === "1"}
                        />
                      </div>
                    </div>


                    <div className="input-container">
                      <label htmlFor="" className="input-label">Type</label>
                      <Select
                        options={FIELD_TYPE}
                        value={FIELD_TYPE.find(type => type.value === question.field_type)}
                        onChange={(e) => {
                          let newQuestions = [...formQuestions]
                          newQuestions[index].field_type = e.value
                          setFormQuestions(newQuestions)
                        }}
                      />
                    </div>



                    <div className="input-container">
                      <label htmlFor="" className="input-label">Smart Tag</label>
                      <Select
                        options={SMART_TAG}
                        value={SMART_TAG.find(tag => tag.value === question.smart_tag)}
                        onChange={(e) => {
                          let newQuestions = [...formQuestions]
                          newQuestions[index].smart_tag = e.value
                          newQuestions[index].type = e.type
                          newQuestions[index].field_type = e.field_type
                          setFormQuestions(newQuestions)
                        }}
                      />
                    </div>

                    {/* Questions */}
                    {question?.field_type === "text" &&
                      <ShortTextComponent data={question.name} type={question.type} onChange={(value) => { }} state={"builder"} />
                    }
                    {question?.field_type === "textarea" &&
                      <LongTextComponent data={question.name} type={question.type} onChange={(value) => { }} state={"builder"} />
                    }
                    {question?.field_type === "select" &&
                      <ListComponent data={question.name} type={question.type} onChange={(value) => {
                        let newQuestions = [...formQuestions]
                        newQuestions[index].field_options = value
                        setFormQuestions(newQuestions)
                      }} state={"builder"} field_options={question.field_options} />
                    }
                    {question?.field_type === "radio" &&
                      <SingleSelectionComponent data={question.name} type={question.type} onChange={(value) => {
                        let newQuestions = [...formQuestions]
                        newQuestions[index].field_options = value
                        setFormQuestions(newQuestions)
                      }} state={"builder"} field_options={question.field_options} />
                    }
                    {question?.field_type === "checkbox" &&
                      <CheckboxComponent data={question.name} type={question.type} onChange={(value) => {
                        let newQuestions = [...formQuestions]
                        newQuestions[index].field_options = value
                        setFormQuestions(newQuestions)
                      }}
                        state={"builder"} field_options={question.field_options} />
                    }
                  </div>
                </div>
              )
            })
              :
              <p className="small gray mt-4 text-center">No questions added</p>
            }

          </div>
          <div style={{height:300}}/>
        </div>
        <div className="w-100">
          <div className="card">
            <p className="card-title">Form Preview</p>
            {formQuestions.length > 0 ? formQuestions.map((question, index) => {
              return (
                <div className='mt-4 pb-2' style={{ borderBottom: index + 1 !== formQuestions?.length ? "2px solid gray" : "" }}>
                  <div className="flex-row flex justify-between items-center">
                    <p className="b medium" style={{ fontSize: 14, marginBottom: 5, }}>Question {index + 1}</p>
                  </div>
                  <p style={{ fontSize: 14, marginBottom: 5, paddingLeft: 2 }}>{question.name}</p>

                  {/* Questions */}
                  {question?.field_type === "text" &&
                    <ShortTextComponent data={question.temp_value} type={question.type} onChange={(value) => {
                      let newQuestions = [...formQuestions]
                      newQuestions[index].temp_value = value
                      setFormQuestions(newQuestions)
                    }} state={"answer"} />
                  }
                  {question?.field_type === "textarea" &&
                    <LongTextComponent data={question.temp_value} type={question.type} onChange={(value) => {
                      let newQuestions = [...formQuestions]
                      newQuestions[index].temp_value = value
                      setFormQuestions(newQuestions)
                    }} state={"answer"} />
                  }
                  {question?.field_type === "select" &&
                    <ListComponent data={question.temp_value} type={question.type} onChange={(value) => {
                      let newQuestions = [...formQuestions]
                      newQuestions[index].temp_value = value
                      setFormQuestions(newQuestions)
                    }} state={"answer"} field_options={question.field_options} />
                  }
                  {question?.field_type === "radio" &&
                    <SingleSelectionComponent data={question.temp_value} type={question.type} onChange={(value) => {
                      let newQuestions = [...formQuestions]
                      newQuestions[index].temp_value = value
                      setFormQuestions(newQuestions)
                    }} state={"answer"} field_options={question.field_options} />
                  }
                  {question?.field_type === "checkbox" &&
                    <CheckboxComponent data={question.temp_value} type={question.type} onChange={(value) => {
                      let newQuestions = [...formQuestions]
                      newQuestions[index].temp_value = value
                      setFormQuestions(newQuestions)
                    }} state={"answer"} field_options={question.field_options} />
                  }
                </div>
              )
            }) :
              <p className="small gray mt-4 text-center">Please add some questions for your forms</p>
            }
            {/* <pre>{JSON.stringify(formQuestions, null, 2)}</pre> */}
          </div>
        </div>
      </div>
    </div>
  )
}
