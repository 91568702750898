import React, { useEffect, useState } from 'react'

export default function Table({ actions, columns, data, className }) {
    const [header, setHeader] = useState(0)

    useEffect(() => {
        let header = document.getElementById("user-header")?.clientHeight
        let subheader = document.getElementById("game-subheader")?.clientHeight
        setHeader(header + subheader)
    }, [])

    return (
        <div className={`overflow-x-auto bg-white ${className}`}>
            <table className="table-auto w-full">
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index} className="border-t border-b border-gray-300 text-left px-2 py-1 text-xs font-bold">{column?.header}</th>
                        ))}
                        {actions && <th className="border-t border-b border-gray-300 text-left px-2 py-1 text-xs font-bold">Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {data?.length > 0 &&data.map((row, index) => (
                        <tr key={index} className='hover:bg-gray-400'>
                            {columns.map((column, index) => (
                                <td key={index} className="border-t border-b border-gray-300 text-left px-2 py-1 text-sm">{column?.data ? column.data(row) : row[column.accessor]}</td> 
                            ))}
                            {actions && <td className="border-t border-b border-gray-300 text-left px-2 py-1 text-sm">{actions(row)}</td>}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
