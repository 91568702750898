import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import FileUploadField from '../../components/FileUploadField';
import Select from 'react-select'
import { MdDelete } from 'react-icons/md';

export default function BulletinFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()

  let bulletin_id = new URLSearchParams(window.location.search).get("bulletin_id")

  const [bulletinData, setBulletinData] = useState({
    title: "",
    remarks: "",
    media_asset_id: "",
    announcements: [],
  })
  const [announcementData, setAnnouncementData] = useState([])

  const fetchBulletinById = async () => {
    if (!bulletin_id || bulletin_id === "new") return
    let endpoint = `${config.endpoint}/bulletin/id/${bulletin_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setBulletinData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const fetchAnnouncements = async () => {
    let endpoint = `${config.endpoint}/announcements/admin`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setAnnouncementData(data.data)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  useEffect(() => {
    fetchBulletinById()
    fetchAnnouncements()
  }, [])


  const createBulletin = async () => {
    let endpoint = `${config.endpoint}/bulletin/`
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...bulletinData,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      navigate("/admin/bulletin/details?bulletin_id=" + data.data.id)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const updateBulletin = async () => {
    if (!bulletin_id || bulletin_id === "new") return
    let endpoint = `${config.endpoint}/bulletin/id/${bulletin_id}`
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...bulletinData,
      }),
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const deleteBulletin = async () => {
    if (!bulletin_id || bulletin_id === "new") return
    let endpoint = `${config.endpoint}/bulletin/id/${bulletin_id}`
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      navigate("/admin/bulletin")
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  return (
    <AdminLayout
      navSelected={"Bulletin"}
      regularContainer={bulletin_id !== "new"}
    >
      <div className="flex-row flex gap-2 alignStart">
        <div className="w-100">
          <div className="card">
            <p className="card-title">Bulletin Information</p>
            {bulletin_id !== "new" &&
              <div className="input-container">
                <label htmlFor="" className="input-label">Header</label>
                <FileUploadField
                  text={"Upload Header"}
                  subtext={"Upload a header image for the bulletin"}
                  max_file_size={5}
                  max_files={1}
                  upload_url={`${config.endpoint}/file/bulletin/id/${bulletin_id}`}
                  onChange={() => { }}
                />

              </div>
            }
            <div className="input-container">
              <label htmlFor="" className="input-label">Name</label>
              <input type="text" className="text-input" placeholder='e.g. 24 Mar 24 Sunday Bulletin (Internal)' value={bulletinData?.title} onChange={(e) => setBulletinData({ ...bulletinData, title: e.target.value })} />
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Remarks</label>
              <textarea className="textarea-input" value={bulletinData?.remarks} placeholder='e.g. Internal Remarks' onChange={(e) => setBulletinData({ ...bulletinData, remarks: e.target.value })} />
            </div>

            <div className="column alignEnd">
              <div className="flex-row flex gap-2 items-center">
                {bulletin_id !== "new" && <button className="button-delete"
                  onClick={() => {
                    deleteBulletin()
                  }}
                >Delete</button>}
                <button className="button"
                  onClick={() => {
                    if (bulletin_id === "new") {
                      createBulletin()
                    } else {
                      updateBulletin()
                    }
                  }}
                >Save</button>
              </div>
            </div>
          </div>
        </div>

        {bulletin_id !== "new" && <div className="w-50">
          <div className="card">
            <p className="card-title">Announcements</p>
            <div className="input-container">
              <label htmlFor="" className='input-label'>Select Announcement</label>
              <Select
                options={announcementData.map((announcement) => {
                  return {
                    value: announcement.id,
                    label: announcement.title
                  }
                })}
                onChange={(selected) => {
                  let status = false
                  for (let index = 0; index < bulletinData?.announcements.length; index++) {
                    if (bulletinData?.announcements[index] == selected.value) {
                      status = true
                      break
                    }
                  }
                  if (status) return
                  setBulletinData({ ...bulletinData, announcements: [...bulletinData.announcements, selected.value] })
                }}
              />
            </div>
          </div>
          <div className="mt-10 card">
            <p className="card-title">Announcements</p>
            {bulletinData?.announcements?.length > 0 ? bulletinData?.announcements.map((announcement_id, index) => {
              let announcement = announcementData.find((announcement) => announcement.id == announcement_id)
              console.log(announcement)
              return (
                <div key={index} style={{ borderBottom: (index + 1 !== bulletinData?.announcements?.length) && "1px solid gray", paddingBlock: 10 }}>
                  <div className="flex-row flex gap-2 alignStart justify-between mb-4">

                    <p className='' style={{ fontSize: 15 }}>{announcement.title}</p>
                    <button className="backgroundLess" style={{ minWidth: 50 }}
                      onClick={() => {
                        let new_announcements = bulletinData.announcements.filter((announcement) => announcement !== announcement_id)
                        setBulletinData({ ...bulletinData, announcements: new_announcements })
                      }}
                    >
                      <MdDelete />
                    </button>
                  </div>
                  <img src={`${config.endpoint}${announcement?.file_data?.ext_file_path}`} alt="" style={{ borderRadius: 15, padding: 0 }} className='card' />
                </div>
              )
            })
              :
              <p className="gray small">Select an announcement</p>
            }
          </div>
        </div>}
      </div>
    </AdminLayout>
  )
}
