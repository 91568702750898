import React, { useEffect, useState, useLayoutEffect } from 'react'
import config from '../app-config'
import { MdExitToApp, MdPerson } from 'react-icons/md'
import { logout, useFetchUserData } from '../utils/AppFunctions'
import { isMobile } from '../utils/helper'

export default function UserHeader({ showNavbar, setShowNavbar }) {
    const [imageHeight, setImageHeight] = useState(document.getElementById("logo-navbar")?.getClientRects()[0].bottom)
    const [contextHeight, setContextHeight] = useState(document.getElementById("context-trigger")?.getClientRects()[0].bottom)
    const user_data = useFetchUserData()

    useLayoutEffect(() => {
        setImageHeight(document?.getElementById("logo-navbar")?.getClientRects()[0].bottom)
    }, [imageHeight])


    const admin_context_menu = [
        // {
        //     title: "Profile",
        //     icon: <MdPerson size={20} color='gray' />,
        //     onClick: () => { window.location.href = "/profile" }
        // },
        {
            title: "Logout",
            icon: <MdExitToApp size={20} color='gray' />,
            onClick: () => { logout() }
        }
    ]

    const [showMenu, setShowMenu] = useState(false)

    return (
        <>
            <div style={{ width: "100%", padding: 10, paddingBlock: 20, backgroundColor: "lightblue", display: "flex", justifyContent: "left", alignItems: "center", flexDirection: "row", position: "sticky", top: 0, zIndex: 10 }} id='user-header'>
                <div className="flex flex-row items-center justify-between sm:flex-col sm:items-end sm:justify-end w-full">
                    {isMobile(window.innerWidth) && <div
                        className="flex-row flex items-center cursor-pointer"
                        onClick={() => {
                            setShowNavbar(true)
                        }}
                    >
                        <img src={config.images.default.logo_white} alt="logo" id="logo-navbar" style={{ height: 50, width: 100, objectFit: "contain", borderRadius: 5 }} />
                    </div>}
                    <div className="flex-row flex gap-4 items-center">
                        <div className="flex-row flex gap-2 items-center cursor-pointer" id='context-trigger'
                            onClick={() => setShowMenu(!showMenu)}
                        >
                            <MdPerson size={20} color='' />
                            <p className="" style={{ fontSize: 13 }}>{user_data?.first_name} {user_data?.last_name}</p>
                        </div>
                    </div>
                </div>
            </div>

            {showMenu &&
                <div style={{ position: "fixed", top: contextHeight, right: 20, backgroundColor: "white", boxShadow: "0px 0px 5px 0px gray", width: 150, borderRadius: 5, zIndex: 10 }}>
                    {admin_context_menu.map((item, index) => {
                        return (
                            <div key={index} className="cursor-pointer row gap-2 items-center" onClick={item.onClick} style={{ padding: 10, borderBottom: "1px solid lightgray" }}>
                                {item.icon}
                                <p style={{ margin: 0, fontSize: 14 }}>{item.title}</p>
                            </div>
                        )
                    })}
                </div>
            }
        </>
    )
}
