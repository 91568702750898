import config from "../app-config.js";
const { default: serverFetch } = require("../utils/serverFetch.js");

export const fetch_event_registration_by_event_id = async (event_id) => {
    return (await serverFetch({
        endpoint: `${config.endpoint}/events/registration/event/id/${event_id}`,
    }))
}

export const event_export_registration_data_by_event_id = async (event_id) => {
    return (await serverFetch({
        endpoint: `${config.endpoint}/events/export/participants/${event_id}`,
    }))
}

export const delete_child_registration_from_session = async (child_id) => {
    return (await serverFetch({
        endpoint: `${config.endpoint}/events/registration/session/group/child/id/${child_id}`,
        method:"DELETE"
    }))
}