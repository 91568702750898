import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from '../../layouts/AdminLayout'
import notify from '../../utils/IANotification';
import AdminEventsNavbar from '../AdminEventsNavbar';
import Switch from "react-switch";
import Select from 'react-select'
import { MdAdd, MdCancel, MdDelete } from 'react-icons/md';
import ShortTextComponent from './form/ShortTextComponent';
import LongTextComponent from './form/LongTextComponent';
import ListComponent from './form/ListComponent';
import SingleSelectionComponent from './form/SingleSelectionComponent';
import CheckboxComponent from './form/CheckboxComponent';
import { capitaliseFirstLetter } from '../../utils/helper';
import { delete_child_registration_from_session } from '../../api/eventAPI';
import ModalLayout from '../../layouts/ModalLayout';

export default function EventRegistrationDetail() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()


  let event_id = new URLSearchParams(window.location.search).get("event_id")
  let selectedItem = new URLSearchParams(window.location.search).get("state") ? new URLSearchParams(window.location.search).get("state") : "general"
  let group_id = new URLSearchParams(window.location.search).get("group_id")

  const [eventData, setEventData] = useState({
    name: "",
    description: "",
    event_start_datetime: "",
    event_end_datetime: "",
    venue: "",
    price: "0",
    is_unlimited_capacity: "0",
    capacity: "100",
    has_accommodation: "0",
    has_transportation: "0",
    accommodation_id: "",
    transportation_id: "",
    registration_start_datetime: "",
    registration_end_datetime: "",
  })
  const [groupRegistrationData, setGroupRegistrationData] = useState([])
  const [registrationData, setRegistrationData] = useState({})
  const [showRemoveChildModal, setShowRemoveChildModal] = useState(false)


  const deleteChildRegistration = async (child_id) => {
    let { data, error, status, message } = await delete_child_registration_from_session(child_id)
    if (error) return notify({ message: message, type: "error" })
    fetchGroupRegistrationByGroupId()
    notify({ message: "Successfully removed user", type: "success" })
  }

  const fetchGroupRegistrationByGroupId = async () => {
    let endpoint = `${config.endpoint}/events/registration/group/id/${group_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setGroupRegistrationData(data.data)
      setRegistrationData(data.data?.child_registration[0])
    } else {
      notify({ type: "error", message: data.message })
    }
  }
  useEffect(() => {
    fetchGroupRegistrationByGroupId()
  }, [])


  const updateRegistrationById = async () => {
    let endpoint = `${config.endpoint}/events/registration/id/${registrationData?.id}`
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(registrationData)
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      fetchGroupRegistrationByGroupId()
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const updateGroupRegistrationById = async () => {
    let endpoint = `${config.endpoint}/events/registration/group/id/${groupRegistrationData?.group_id}`
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...groupRegistrationData
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      fetchGroupRegistrationByGroupId()
    } else {
      notify({ type: "error", message: data.message })
    }
  }


  return (
    <div>
      <ModalLayout modalTitle="Remove Registration" isVisible={showRemoveChildModal} setIsVisible={setShowRemoveChildModal}>
        <p>Are you sure you want to remove this registration? Any payment related to this registrant will not be removed</p>
        <div className="flex flex-row items-center justify-end w-full gap-2 mt-3">
          <button className="button-cta" onClick={() => setShowRemoveChildModal(false)}>No</button>
          <button className="button" onClick={() => {
            deleteChildRegistration(registrationData?.id)
            setShowRemoveChildModal(false)
          }}>Yes</button>
        </div>
      </ModalLayout>
      <div className="flex flex-row items-start gap-2">
        <div className="w-full">
          <div className="card">
            <label htmlFor="" className="card-title">Registration Form</label>
            {registrationData?.field?.length > 0 && registrationData?.field.map((item, index) => {
              return (
                <>
                  <div className="input-container">
                    <label htmlFor="" className="input-label">{item?.name}</label>
                    {item?.field_type === "text" && (
                      <ShortTextComponent
                        key={index}
                        item={item}
                        data={item?.answer}
                        type={item?.type}
                        state="answer"
                        onChange={(value) => {
                          let newData = { ...registrationData }
                          newData.field[index].answer = value
                          setRegistrationData(newData)
                        }}
                      />
                    )}
                    {item?.field_type === "textarea" && (
                      <LongTextComponent
                        key={index}
                        item={item}
                        data={item?.answer}
                        state="answer"
                        onChange={(value) => {
                          let newData = { ...registrationData }
                          newData.field[index].answer = value
                          setRegistrationData(newData)
                        }}
                      />
                    )}
                    {item?.field_type === "select" && (
                      <ListComponent
                        key={index}
                        item={item}
                        data={item?.answer}
                        state="answer"
                        field_options={item?.field_options}
                        onChange={(value) => {
                          let newData = { ...registrationData }
                          newData.field[index].answer = value
                          setRegistrationData(newData)
                        }}
                      />
                    )}
                    {item?.field_type === "radio" && (
                      <SingleSelectionComponent
                        key={index}
                        item={item}
                        data={item?.answer}
                        state="answer"
                        field_options={item?.field_options}
                        onChange={(value) => {
                          let newData = { ...registrationData }
                          newData.field[index].answer = value
                          setRegistrationData(newData)
                        }}
                      />
                    )}
                    {item?.field_type === "checkbox" && (
                      <CheckboxComponent
                        key={index}
                        item={item}
                        data={item?.answer}
                        state="answer"
                        field_options={item?.field_options}
                        onChange={(value) => {
                          let newData = { ...registrationData }
                          newData.field[index].answer = value
                          setRegistrationData(newData)
                        }}
                      />
                    )}
                  </div>
                </>
              )
            })}

            <div className="btn-grp">
              <button className="button"
                onClick={() => {
                  updateRegistrationById()
                  updateGroupRegistrationById()
                }}
              >Save</button>
            </div>
          </div>
        </div>
        <div className="w-8/12">
          <div className="card">
            <label htmlFor="" className="card-title mb-2">Group</label>
            {groupRegistrationData?.child_registration?.length > 0 && groupRegistrationData?.child_registration?.map((item, index) => {
              return (
                <div key={index} onClick={() => {
                  setRegistrationData(item)
                }} className="card p-2 cursor-pointer mb-2">
                  <div className="flex flex-row items-center justify-between">
                    <div>
                      <p className="text-sm">{item?.field?.find((field) => field.smart_tag === "fullname")?.answer}</p>
                      <p className="text-xs">{item?.field?.find((field) => field.smart_tag === "email")?.answer}</p>
                    </div>
                    <button
                      onClick={() => {
                        setShowRemoveChildModal(true)
                        setRegistrationData(item)
                      }}
                    >
                      <MdDelete />
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
          {eventData?.has_accommodation == 1 && <div className="card mt-2">
            <label htmlFor="" className="card-title">Accommodation</label>
            <div className="flex flex-row items-start gap-2">
              <img src={`${config.endpoint}${registrationData?.accommodation_data?.file_data?.ext_file_path}`} alt="" className="size-10 rounded-md" />
              <div>
                {/* <pre>{JSON.stringify(registrationData?.accommodation_data, null, 2)}</pre> */}
                <p htmlFor="">{registrationData?.accommodation_data?.name}</p>
                <p className='text-gray-500 text-xs' htmlFor="">{registrationData?.accommodation_data?.description}</p>
              </div>
            </div>
            {/* <pre>{JSON.stringify(registrationData?.accommodation_data , null,2)}</pre> */}
          </div>}
          <div className="card mt-2">
            <label htmlFor="" className="card-title">Status</label>
            <div className="input-container">
              <label htmlFor="" className="input-label">Booking Status</label>
              <Select
                options={[
                  { value: "pending", label: "Pending" },
                  { value: "pending-payment", label: "Pending Payment" },
                  { value: "completed", label: "Completed" },
                  { value: "cancelled", label: "Cancelled" },
                  { value: "waitlisted", label: "Waitlisted" },
                  { value: "refunded", label: "Refunded" },
                ]}
                value={{ value: groupRegistrationData?.status, label: capitaliseFirstLetter(groupRegistrationData?.status) }}
                onChange={(e) => {
                  setGroupRegistrationData({ ...groupRegistrationData, status: e.value })
                }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Payment Status</label>
              <Select
                options={[
                  { value: "0", label: "Unpaid" },
                  { value: "1", label: "Paid" },
                ]}
                value={{ value: groupRegistrationData?.is_paid, label: groupRegistrationData?.is_paid === "1" ? "Paid" : "Unpaid" }}
                onChange={(e) => {
                  if (e.value === "1") {
                    setGroupRegistrationData({ ...groupRegistrationData, is_paid: e.value, status: "completed", payment_datetime: new Date() })
                  } else {
                    setGroupRegistrationData({ ...groupRegistrationData, is_paid: e.value })
                  }
                }}
              />
            </div>
            <>
              <div className="input-container">
                <label htmlFor="" className="input-label">Payment Amount</label>
                <input type="text" className="text-input" disabled={groupRegistrationData?.is_paid == 1} value={groupRegistrationData?.payment_amount} onChange={(e) => {
                  setGroupRegistrationData({ ...groupRegistrationData, payment_amount: e.target.value })
                }} />
              </div>
            </>
          </div>
          <div className="card mt-2">
            <label htmlFor="" className="card-title">Remarks</label>
            <div className="input-container">
              <label htmlFor="" className="input-label">Internal Remarks</label>
              <textarea className="text-input" value={groupRegistrationData?.remarks} onChange={(e) => {
                setGroupRegistrationData({ ...groupRegistrationData, remarks: e.target.value })
              }} />
            </div>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(groupRegistrationData, null, 2)}</pre> */}
    </div>
  )
}
