const serverFetch = async ({ endpoint, method, headers, body, credentials }) => {
    if (!method) method = "GET"
    if (!headers) headers = {
        "Content-Type": "application/json",
        "Accept": "application/json"
    }
    if (!credentials) credentials = "include"
    try {
        let response = await fetch(endpoint, {
            method,
            headers,
            body,
            credentials
        })
        let data = await response.json() || {}
        let error = null
        let message = data?.message || "An error occurred"
        if (response.status.toString().startsWith("4") || response.status.toString().startsWith("5")) {
            error = message
            data = null
        }

        return { data: data?.data, error: error, status: response.status, message }
    } catch (error) {
        console.error(error)
        return { data: null, error, status: 500 }
    }
}

export default serverFetch