import React, { useLayoutEffect, useState } from 'react'
import { MdAirplanemodeActive, MdAppBlocking, MdChurch, MdClass, MdEditDocument, MdFlag, MdFlagCircle, MdGroup, MdHome, MdHotel, MdLaptop, MdPerson, MdPerson3, MdPersonPin, MdPolicy, MdQrCode, MdSettings, MdStop, MdVpnLock } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { TbReport } from "react-icons/tb";
import { IoDocumentsOutline, IoEaselOutline, IoHomeOutline, IoJournalOutline, IoLibraryOutline, IoMegaphone, IoMegaphoneOutline, IoNewspaperOutline, IoPeopleOutline, IoSettingsOutline } from "react-icons/io5";

export default function AdminEventsNavbar({ selected, has_accommodation, has_transportation }) {

    const [headerHeight, setHeaderHeight] = useState(document?.getElementById("admin-header")?.clientHeight + 1)

    useLayoutEffect(() => {
        setHeaderHeight(document?.getElementById("admin-header")?.clientHeight + 1)
    }, [])

    const ICON_SIZE = 20

    let event_id = new URLSearchParams(window.location.search).get("event_id")
    let state = new URLSearchParams(window.location.search).get("state")
    selected = state ? state : "general"

    const ITEMS = [
        {
            "name": "General",
            "state": "general",
            "link": `/admin/events/details?state=general&event_id=${event_id}`,
            "icon": <MdHome size={ICON_SIZE} />,
            active: true,
        },
        {
            "name": "Accommodation",
            "state": "accommodation",
            "link": `/admin/events/details?state=accommodation&event_id=${event_id}`,
            "icon": <MdHotel size={ICON_SIZE} />,
            active: has_accommodation == "1" && event_id !="new" ? true : false,
        },
        // {
        //     "name": "Transportation",
        //     "state": "transportation",
        //     "link": `/admin/events/details?state=transportation&event_id=${event_id}`,
        //     "icon": <MdAirplanemodeActive size={ICON_SIZE} />,
        //     active: has_transportation == "1" && event_id !="new" ? true : false,
        // },
        {
            "name": "Registration",
            "state": "registration",
            "link": `/admin/events/details?state=registration&event_id=${event_id}`,
            "icon": <MdLaptop size={ICON_SIZE} />,
            active: event_id !="new" ? true : false,
        },
        {
            "name": "Forms",
            "state": "forms",
            "link": `/admin/events/details?state=forms&event_id=${event_id}`,
            "icon": <MdEditDocument size={ICON_SIZE} />,
            active: event_id !="new" ? true : false,
        },
        // {
        //     "name": "Participants",
        //     "state": "participants",
        //     "link": `/admin/events/details?state=participants&event_id=${event_id}`,
        //     "icon": <MdGroup size={ICON_SIZE} />,
        //     active: event_id !="new" ? true : false,
        // },
        {
            "name": "Waitlist",
            "state": "waitlist",
            "link": `/admin/events/details?state=waitlist&event_id=${event_id}`,
            "icon": <MdStop size={ICON_SIZE} />,
            active: event_id !="new" ? true : false,
        },
        {
            "name": "Check-Ins",
            "state": "check-ins",
            "link": `/admin/events/details?state=check-ins&event_id=${event_id}`,
            "icon": <MdQrCode size={ICON_SIZE} />,
            active: event_id !="new" ? true : false,
        },
    ]
    return (
        <div id='course-nav' className='row gap-4 mb-4 items-center hide-scrollbars' style={{
            width: "100%",
            borderBottom: "1px solid #E5E5E5",
            position: "sticky",
            top: headerHeight,
            zIndex: 5,
            backgroundColor: "#eff1f6",
            marginTop: 10,
            marginBottom: 10,
            paddingTop: 10,
            overflowY: "auto",
        }}>
            {ITEMS.map((item, index) => {
                if (!item.active) {
                    return null
                }
                return (
                    <div className={`row gap-2 items-center pr-10 cursor-pointer`} style={{ borderBottom: selected === item.state ? "2px solid #0222FF" : "", }} onClick={() => {
                        window.location.href = item.link
                        selected = item.name
                    }}>
                        {item.icon}
                        <p style={{ fontSize: 12, fontWeight: 500 }}>{item.name}</p>
                    </div>
                )
            })}
        </div>
    )
}
