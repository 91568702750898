import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { event_export_registration_data_by_event_id, fetch_event_registration_by_event_id } from '../../api/eventAPI'
import Table from '../app-components/Table'
import { capitaliseFirstLetter } from '../../utils/helper'
import { useNavigate } from 'react-router-dom'
import { MdAdd, MdDownload } from 'react-icons/md'
import notify from '../../utils/IANotification'
import config from '../../app-config'

export default function EventRegistration() {
    const navigate = useNavigate()
    const [eventRegistration, setEventRegistration] = useState([])
    const event_id = new URLSearchParams(window.location.search).get("event_id")
    useEffect(() => {
        fetchEventRegistration()
    }, [])

    const fetchEventRegistration = async () => {
        let { data, error, status, message } = await fetch_event_registration_by_event_id(event_id)
        if (error) return console.error(message)
        setEventRegistration(data)
    }

    const requestDataExport = async () => {
        let { data, error, status, message } = await event_export_registration_data_by_event_id(event_id)
        if (error) return notify({ message: message, type: "error" })
        notify({ message: "Data exported successfully", type: "success" })
        window.open(`${config.endpoint}${data}`, "_blank")
    }

    let table_columns = [
        {
            header: "Name",
            data: (row) => row?.child_registration[0]?.field?.find((field) => field.smart_tag === "fullname")?.answer
        },
        {
            header: "Email",
            data: (row) => row?.child_registration[0]?.field?.find((field) => field.smart_tag === "email")?.answer
        },
        {
            header: "Payment Status",
            data: (row) => row?.is_paid == 1 ? "Paid" : (!row?.payment_amount || row?.payment_amount == 0) ? "Not required" : "Unpaid"
        },
        {
            header: "Registration Status",
            data: (row) => capitaliseFirstLetter(row?.status)
        },
        {
            header: "Total Amount",
            data: (row) => `$${row?.payment_amount}`
        },
        {
            header: "Group Size",
            data: (row) => row?.child_registration?.length
        },
    ]


    return (
        <div>
            <div className="mb-2 flex flex-row items-center gap-2 justify-end">
                <button className="pri-btn button flex flex-row items-center gap-2">
                    <MdAdd />
                    <span>New Registration</span>
                </button>
                <button className="pri-btn button flex flex-row items-center gap-2"
                    onClick={() => requestDataExport()}
                >
                    <MdDownload />
                    <span>Export Registration</span>
                </button>
            </div>
            <Table
                columns={table_columns}
                data={eventRegistration}
                actions={(row) => {
                    return (
                        <>
                            <button className="text-xs hover:underline" onClick={() => {
                                navigate(`/admin/events/details?state=registration_detail&event_id=${event_id}&registration_id=${row.id}&group_id=${row.group_id}`, { replace: false })
                            }}>View</button>
                        </>
                    )
                }}
            />
            {/* <pre>{JSON.stringify(eventRegistration, null, 2)}</pre> */}
        </div>
    )
}
